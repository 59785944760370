// extracted by mini-css-extract-plugin
export var jobs = "jobs-module--jobs--Io1wU";
export var bgsvg = "jobs-module--bgsvg--ZCGm9";
export var link = "jobs-module--link--zvLYN";
export var title = "jobs-module--title--0fKbK";
export var readMore = "jobs-module--readMore--lkitm";
export var bounce = "jobs-module--bounce--w6cS8";
export var info = "jobs-module--info--yqDlR";
export var jobTitle = "jobs-module--job-title--WMyP5";
export var quickDescription = "jobs-module--quick-description--4eU5U";
export var location = "jobs-module--location--GRR0W";
export var type = "jobs-module--type--mGj6U";