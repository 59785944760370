/**
 * Our Benefits-block
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';
import {Link} from 'gatsby';
import className from 'classnames';
import PropTypes from 'prop-types';

// Peritus
import Block, {BlockShape} from 'components/Block';
import {CreateLocalLink} from 'utilities';
import {Translate} from 'utilities';

// Styles
import * as styles from './jobs.module.scss';

const Jobs = ({categories, title, ...props}) => {
    let jobs = categories.reduce((currentJobs, cat) => [
        ...currentJobs,
        ...cat.jobs.nodes,
    ], []);

    const blockClasses = className({
        [styles.jobs]: true,
        [styles.bgsvg]: !!props.rowSettings.background.includes('svg'),
    });

    return (<Block className={blockClasses} {...props}>
        {title && <h3 className={styles.title} dangerouslySetInnerHTML={{__html: title}}/>}
        {jobs && jobs.map((job, key) => (<Link to={CreateLocalLink(job.uri)} className={styles.link} key={key}>
            <div className={styles.info}>
                <p className={styles.jobTitle} dangerouslySetInnerHTML={{__html: job.title}}/>
                <span className={styles.quickDescription}>
                    <p className={styles.location} dangerouslySetInnerHTML={{__html: job.acf.employmentLocation}}/>
                    <p className={styles.type} dangerouslySetInnerHTML={{__html: job.acf.employmentType}}/>
                    <p className={styles.readMore}>{Translate('readMore')}</p>
                </span>
            </div>
        </Link>))}
    </Block>);
};

Jobs.propTypes = {
    ...BlockShape,
    title: PropTypes.string,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            jobs: PropTypes.shape({
                nodes: PropTypes.arrayOf(
                    PropTypes.shape({
                        title: PropTypes.string.isRequired,
                        uri: PropTypes.string.isRequired,
                        acf: PropTypes.shape({
                            employmentLocation: PropTypes.string.isRequired,
                            employmentType: PropTypes.string.isRequired,
                        }),
                    }),
                ),
            }),
        }),
    ).isRequired,
};

export default Jobs;

